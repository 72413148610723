import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { SEO } from "~/components/SEO";
import { useStringsContext } from "~/contexts/strings-context";
import { PageProps } from "~/types/global";
import Mission from "~/assets/svg/mission";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            varqaschool
          }
    }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 2rem;
  margin: 5rem 0;
  @media(max-width: 1300px) {
      flex-direction: column-reverse;
      margin-bottom: 3rem;
      height: auto;
      padding: 0 1.2rem;
    }
`;
const Text = styled.h1`
  font-size: 1.3rem;
  color: whitesmoke;
  font-family: "Cinzel";
  line-height: 2rem;
  letter-spacing: 1px;
  padding-top: .5rem;
  list-style-type: upper-roman;
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  max-width: 50vw;
  @media(max-width: 1300px) {
    max-width: 100vw;
    }
    ol {
      list-style-type: square;
      color: whitesmoke;
    }
    li {
      ::marker {
        font-size: 1.2rem;
      }
    }
`;

const TitleMission = styled.h1`
    display: flex;
    line-height: 2.7rem;
    text-align: start;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f8f8f8;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
`;

const MissionPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Varqa School - Mission"
        description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
        keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
        url={props.location.pathname}
      />
      <Container>
        <Mission/>
        <TextContainer>
          <TitleMission>Our Mission</TitleMission>
        <ol>
          <li>
            <Text>Our mission is to provide a beautiful and positive learning environment to all the children who come to school to learn.</Text>
          </li>
          <li>  
            <Text>To work to preserve the ‘childhood’ of every child and yet prepare him for the challenges of life.</Text>
          </li>
          <li>
            <Text>To nurture the growth and development of children and encourage them to experiment, think and learn.</Text>
          </li>
          <li>
            <Text>To be a friend and guide to the children in their path of discovery.</Text>
          </li>
          <li>
            <Text>To help the children to become good learners, good human beings and responsible citizens of tomorrow.</Text>
          </li>
          <li>
            <Text>We are a dynamic community that respects individually, embraces diversity and prepares the students to take their place as global citizens.</Text>
          </li>
        </ol>
        </TextContainer>
        
      </Container>
    </>
  )
}

export default MissionPage
